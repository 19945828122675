var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.step == 'index')?_c('div',{staticClass:"index"},[_c('div',{staticClass:"p-3"},[_c('img',{staticClass:"bottom-bg",attrs:{"src":require("./images/index-bottom-bg.png")}}),_c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('div',{staticClass:"index-wrapper"},[_c('h5',[_vm._v("法國皇家")]),_c('h5',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{attrs:{"width":"15","src":require("./images/wink.png")}}),_vm._v(" 您的專屬寵物健康專家 "),_c('img',{attrs:{"width":"15","src":require("./images/wink.png")}})]),_c('img',{staticClass:"my-3",attrs:{"src":require("./images/index-title.png")}}),_c('div',[_vm._v("馬上填寫問卷")]),_c('div',[_vm._v("獲得毛孩專屬營養訊息")]),_c('button',{staticClass:"btn button gold animate mt-3",on:{"click":function () {
                _vm.step = _vm.$route.query.gift == 'false' ? 'header-2' : 'header-1';
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" 開始問卷 "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])])])]),_c('img',{staticClass:"bottom",attrs:{"src":require("./images/index-bottom.png")}}),_c('img',{staticClass:"bottom ball",attrs:{"src":require("./images/index-bottom-ball.png")}})],1)]):(_vm.step == 'header-1')?_c('div',{staticClass:"survey p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('div',{staticClass:"number mb-3"},[_vm._v("Q"+_vm._s(_vm.index))]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.survey.header[0].question))]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.survey.header[0].helper))]),_c('b-select',{staticClass:"mt-3",attrs:{"options":_vm.survey.header[0].options},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- 請選擇 --")])]},proxy:true}]),model:{value:(_vm.result.router),callback:function ($$v) {_vm.$set(_vm.result, "router", $$v)},expression:"result.router"}}),_c('button',{staticClass:"btn button gold mt-3",attrs:{"disabled":!_vm.result.router},on:{"click":function () {
                _vm.step = 'header-2';
                _vm.index++;
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" 下一題 "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])])],1)])],1)]):(_vm.step == 'header-2')?_c('div',{staticClass:"survey p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('div',{staticClass:"number mb-3"},[_vm._v("Q"+_vm._s(_vm.index))]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.survey.header[1].question))]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.survey.header[1].helper))]),_vm._l((_vm.survey.header[1].options),function(i){return _c('button',{key:i.value,class:[
              'btn',
              'button',
              'mt-3',
              i.value == _vm.surveyBodyValue && 'active' ],on:{"click":function () {
                _vm.surveyBodyValue = i.value;
                _vm.surveyBody = _vm.survey[i.value];
                _vm.result.pet_type = i.text;

                _vm.result.body = [
                  {
                    key: _vm.surveyBody[0].key,
                    profiles: [
                      { variety: null, size: null, year: null, month: null } ],
                    feed: [],
                    info: [[], []],
                  } ];
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(_vm._s(i.text))])])}),_c('button',{staticClass:"btn button gold mt-3",attrs:{"disabled":!_vm.surveyBody},on:{"click":function () {
                _vm.step = 'body-1';
                _vm.index++;
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" 下一題 "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])])],2)])],1)]):(_vm.step == 'body-1' && _vm.surveyBody)?_c('div',{staticClass:"survey p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[(_vm.surveyBodyValue == 'dog')?_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-dog.png")}}):(_vm.surveyBodyValue == 'cat')?_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-cat.png")}}):_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('div',{staticClass:"number mb-3"},[_vm._v("Q"+_vm._s(_vm.index))]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].question))]),_vm._l((_vm.result.body[_vm.bodyIndex].profiles),function(p,pdx){return _c('div',{key:pdx,staticClass:"mt-5"},[(pdx > 0)?_c('hr'):_vm._e(),_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].variety.question)+" ")]),_c('b-select',{staticClass:"mt-3",on:{"change":function (option) {
                  if (option.size != undefined) {
                    p.size = option.size[0];
                  }
                }},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- 請選擇 --")])]},proxy:true}],null,true),model:{value:(p.variety),callback:function ($$v) {_vm.$set(p, "variety", $$v)},expression:"p.variety"}},_vm._l((_vm.surveyBody[_vm.bodyIndex * 3 + 0].variety
                  .options),function(i,idx){return _c('b-select-option',{key:idx,attrs:{"value":i}},[_vm._v(" "+_vm._s(i.text)+" ")])}),1),(
                p.variety &&
                p.variety.size != undefined &&
                p.variety.size.length > 1
              )?[_c('h5',{staticClass:"mb-0 mt-3"},[_vm._v(" "+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].size.question)+" ")]),_c('b-select',{staticClass:"mt-3",attrs:{"options":p.variety.size},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- 請選擇 --")])]},proxy:true}],null,true),model:{value:(p.size),callback:function ($$v) {_vm.$set(p, "size", $$v)},expression:"p.size"}})]:_vm._e(),_c('h5',{staticClass:"mb-0 mt-3"},[_vm._v(" "+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].age.question)+" ")]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].age.helper))]),_c('div',{staticClass:"d-flex mt-3 align-items-center"},[_c('b-select',{attrs:{"options":_vm.surveyBody[_vm.bodyIndex * 3 + 0].age.year},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- 請選擇 --")])]},proxy:true}],null,true),model:{value:(p.year),callback:function ($$v) {_vm.$set(p, "year", $$v)},expression:"p.year"}}),_c('span',{staticClass:"mx-3"},[_vm._v("歲")]),_c('b-select',{attrs:{"options":_vm.surveyBody[_vm.bodyIndex * 3 + 0].age.month},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- 請選擇 --")])]},proxy:true}],null,true),model:{value:(p.month),callback:function ($$v) {_vm.$set(p, "month", $$v)},expression:"p.month"}}),_c('span',{staticClass:"mx-3"},[_vm._v("月")])],1),(pdx > 0)?_c('button',{staticClass:"btn button mt-3",on:{"click":function () {
                  _vm.result.body[_vm.bodyIndex].profiles.pop();
                }}},[_c('div',{staticClass:"wrapper"},[_vm._v("移除")])]):_vm._e()],2)}),(_vm.result.body[_vm.bodyIndex].profiles.length < 10)?_c('button',{staticClass:"btn button mt-3",on:{"click":function () {
                _vm.result.body[_vm.bodyIndex].profiles.push({
                  variety: null,
                  size: null,
                  year: null,
                  month: null,
                });
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" 還有其他"+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].name)+" ")])]):_vm._e(),_c('button',{staticClass:"btn button gold mt-3",attrs:{"disabled":_vm.profileDisabled(_vm.result.body[_vm.bodyIndex].profiles)},on:{"click":function () {
                _vm.result.body[_vm.bodyIndex].profiles.forEach(function (p) {
                  p.key = _vm.surveyBody[_vm.bodyIndex * 3 + 0].key;
                });

                _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 0].next;
                _vm.index++;
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" "+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 0].button)+" "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])]),_c('button',{staticClass:"btn btn-link text-dark h5 font-weight-bolder mt-3",on:{"click":function () {
                _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 0].prev;
                if (_vm.surveyBodyValue == 'both' && _vm.bodyIndex == 1) {
                  _vm.bodyIndex--;
                }
                _vm.index--;
              }}},[_c('i',{staticClass:"fa fa-caret-left",attrs:{"aria-hidden":"true"}}),_vm._v(" 回上一頁 ")])],2)])],1)]):(_vm.step == 'body-2')?_c('div',{staticClass:"survey p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[(_vm.surveyBodyValue == 'dog')?_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-dog.png")}}):(_vm.surveyBodyValue == 'cat')?_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-cat.png")}}):_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('div',{staticClass:"number mb-3"},[_vm._v("Q"+_vm._s(_vm.index))]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 1].question))]),_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 1].sub))]),_vm._l((_vm.surveyBody[_vm.bodyIndex * 3 + 1].options),function(i,idx){return _c('button',{key:idx,class:[
              'btn',
              'button',
              'mt-3',
              _vm.result.body[_vm.bodyIndex].feed.includes(i.text) && 'active' ],on:{"click":function () {
                var els = _vm.result.body[_vm.bodyIndex].feed;
                els.includes(i.text)
                  ? els.splice(els.indexOf(i.text), 1)
                  : els.push(i.text);
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" "+_vm._s(i.text)+" "),_c('div',{staticClass:"small h6 mb-0 mt-1"},[_vm._v(_vm._s(i.helper))])])])}),_c('button',{staticClass:"btn button gold mt-3",attrs:{"disabled":_vm.result.body[_vm.bodyIndex].feed.length == 0},on:{"click":function () {
                _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 1].next;
                _vm.index++;
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" "+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 1].button)+" "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])]),_c('button',{staticClass:"btn btn-link text-dark h5 font-weight-bolder mt-3",on:{"click":function () {
                _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 1].prev;
                _vm.index--;
              }}},[_c('i',{staticClass:"fa fa-caret-left",attrs:{"aria-hidden":"true"}}),_vm._v(" 回上一頁 ")])],2)])],1)]):(_vm.step == 'body-3')?_c('div',{staticClass:"survey body-3 p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[(_vm.surveyBodyValue == 'dog')?_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-dog.png")}}):(_vm.surveyBodyValue == 'cat')?_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-cat.png")}}):_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('div',{staticClass:"number mb-3"},[_vm._v("Q"+_vm._s(_vm.index))]),_c('h5',[_vm._v(_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 2].question))]),_c('h6',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 2].sub)}}),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 2].helper))]),_vm._l((_vm.surveyBody[_vm.bodyIndex * 3 + 2].questions),function(i,idx){return _c('div',{key:idx,staticClass:"card mb-4"},[_c('div',{staticClass:"card-header p-2 bg-danger text-white"},[_vm._v(" "+_vm._s(i.question)+" "),_c('div',[_vm._v("▼")])]),_c('div',{staticClass:"card-body py-2"},[_c('b-form-checkbox-group',{attrs:{"options":i.options},model:{value:(_vm.result.body[_vm.bodyIndex].info[idx]),callback:function ($$v) {_vm.$set(_vm.result.body[_vm.bodyIndex].info, idx, $$v)},expression:"result.body[bodyIndex].info[idx]"}})],1)])}),_c('button',{staticClass:"btn button gold mt-3",attrs:{"disabled":!(
                _vm.result.body[_vm.bodyIndex].info.length == 2 &&
                _vm.result.body[_vm.bodyIndex].info.every(function (x) {
                  return x.length > 0;
                })
              )},on:{"click":function () {
                if (
                  _vm.surveyBodyValue == 'both' &&
                  _vm.surveyBody[_vm.bodyIndex * 3 + 2].next != 'buy_product'
                ) {
                  _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 2].next;

                  _vm.bodyIndex++;

                  _vm.result.body.push({
                    key: _vm.surveyBody[_vm.bodyIndex * 3].key,
                    profiles: [
                      { variety: null, size: null, year: null, month: null } ],
                    feed: [],
                    info: [[], []],
                  });

                  _vm.index++;
                } else {
                  _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 2].next;
                  _vm.index++;
                }
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" "+_vm._s(_vm.surveyBody[_vm.bodyIndex * 3 + 2].button)+" "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])]),_c('button',{staticClass:"btn btn-link text-dark h5 font-weight-bolder mt-3",on:{"click":function () {
                _vm.step = _vm.surveyBody[_vm.bodyIndex * 3 + 2].prev;
                _vm.index--;
              }}},[_c('i',{staticClass:"fa fa-caret-left",attrs:{"aria-hidden":"true"}}),_vm._v(" 回上一頁 ")])],2)])],1)]):(_vm.step == 'buy_product')?_c('div',{staticClass:"survey buy_product p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('div',{staticClass:"number mb-3"},[_vm._v("Q"+_vm._s(_vm.index))]),_c('h5',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.survey.buy_product.question)}}),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.survey.buy_product.sub))]),_vm._l((_vm.survey.buy_product.options),function(i,idx){return _c('button',{key:idx,class:[
              'btn',
              'button',
              'mt-3',
              _vm.result.buy_product == i.value && 'active' ],on:{"click":function () {
                _vm.result.buy_product = i.value;
                _vm.$forceUpdate();
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v(" "+_vm._s(i.text)+" ")])])}),_c('button',{staticClass:"btn button gold mt-3",attrs:{"disabled":!_vm.result.buy_product},on:{"click":_vm.send}},[_c('div',{staticClass:"wrapper"},[_vm._v(" "+_vm._s(_vm.survey.buy_product.button)+" "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])])],2)])],1)]):(_vm.step == 'finished')?_c('div',{staticClass:"finished"},[_c('div',{staticClass:"survey p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[_c('div',{staticClass:"text-center d-flex flex-column finished-wrapper"},[_c('h3',{staticClass:"text-danger font-weight-bold mt-4"},[_vm._v("感謝您的填寫")]),_vm._m(0),_c('div',{staticClass:"mt-4"},[_vm._v("如有任何資訊更新")]),_c('div',[_vm._v("請至帳號選單點選更多服務")]),_vm._m(1),_c('div',{staticClass:"bottom mt-auto"},[_c('img',{attrs:{"src":require("./images/finished-bottom.png")}}),_c('button',{staticClass:"btn button gold mt-3",on:{"click":_vm.cancel}},[_vm._m(2)])])])])])]):(_vm.step == 'reset')?_c('div',{staticClass:"survey reset p-3"},[_c('div',{staticClass:"survey-wrapper p-3"},[_c('img',{staticClass:"banner",attrs:{"src":require("./images/survey-both.png")}}),_c('b-card',{staticClass:"text-center paper",attrs:{"no-body":""}},[_c('div',{staticClass:"px-4 py-5"},[_c('h5',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("溫馨提醒")]),_c('div',{staticClass:"mt-5"},[_vm._v("您已填寫過問卷")]),_c('div',{},[_vm._v("繼續填寫將會")]),_c('div',{staticClass:"h5 text-danger"},[_vm._v("更新並覆蓋過去所有資料")]),_c('button',{staticClass:"btn button mt-5",on:{"click":function () {
                _vm.step = 'index';
              }}},[_c('div',{staticClass:"wrapper"},[_vm._v("是，繼續填寫")])]),_c('button',{staticClass:"btn button mt-3",on:{"click":_vm.cancel}},[_c('div',{staticClass:"wrapper"},[_vm._v("否，關閉問卷")])])])])],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"mt-4"},[_vm._v(" 您會收到 "),_c('br'),_vm._v(" 專屬毛孩訊息及活動 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_vm._v(" 填寫「"),_c('span',{staticClass:"text-danger font-weight-bolder"},[_vm._v("寵物資料更新")]),_vm._v("」 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_vm._v(" 回到LINE "),_c('img',{attrs:{"src":require("./images/footprint.png")}})])}]

export { render, staticRenderFns }