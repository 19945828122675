<template>
  <div class="container">
    <div v-if="step == 'index'" class="index">
      <div class="p-3">
        <img src="./images/index-bottom-bg.png" class="bottom-bg" />
        <b-card class="p-2" no-body>
          <div class="index-wrapper">
            <h5>法國皇家</h5>
            <h5 class="d-flex align-items-center justify-content-center">
              <img width="15" src="./images/wink.png" />
              您的專屬寵物健康專家
              <img width="15" src="./images/wink.png" />
            </h5>

            <img class="my-3" src="./images/index-title.png" />

            <div>馬上填寫問卷</div>
            <div>獲得毛孩專屬營養訊息</div>
            <!-- <div>
              更有機會<span class="text-danger font-weight-bolder"
                >參與抽獎</span
              >
            </div> -->

            <button
              @click="
                () => {
                  step = $route.query.gift == 'false' ? 'header-2' : 'header-1';
                }
              "
              class="btn button gold animate mt-3"
            >
              <div class="wrapper">
                開始問卷
                <img src="./images/footprint.png" />
              </div>
            </button>
          </div>
        </b-card>
        <img src="./images/index-bottom.png" class="bottom" />
        <img src="./images/index-bottom-ball.png" class="bottom ball" />
      </div>
    </div>

    <!--header-1-->
    <div class="survey p-3" v-else-if="step == 'header-1'">
      <div class="survey-wrapper p-3">
        <img src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <div class="number mb-3">Q{{ index }}</div>
            <h5 class="mb-0">{{ survey.header[0].question }}</h5>
            <small class="text-secondary">{{ survey.header[0].helper }}</small>

            <b-select
              class="mt-3"
              :options="survey.header[0].options"
              v-model="result.router"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- 請選擇 --</b-form-select-option
                >
              </template>
            </b-select>

            <button
              @click="
                () => {
                  step = 'header-2';
                  index++;
                }
              "
              :disabled="!result.router"
              class="btn button gold mt-3"
            >
              <div class="wrapper">
                下一題
                <img src="./images/footprint.png" />
              </div>
            </button>
          </div>
        </b-card>
      </div>
    </div>

    <!--header-2-->
    <div class="survey p-3" v-else-if="step == 'header-2'">
      <div class="survey-wrapper p-3">
        <img src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <div class="number mb-3">Q{{ index }}</div>
            <h5 class="mb-0">{{ survey.header[1].question }}</h5>
            <small class="text-secondary">{{ survey.header[1].helper }}</small>

            <button
              :class="[
                'btn',
                'button',
                'mt-3',
                i.value == surveyBodyValue && 'active',
              ]"
              v-for="i in survey.header[1].options"
              :key="i.value"
              @click="
                () => {
                  surveyBodyValue = i.value;
                  surveyBody = survey[i.value];
                  result.pet_type = i.text;

                  result.body = [
                    {
                      key: surveyBody[0].key,
                      profiles: [
                        { variety: null, size: null, year: null, month: null },
                      ],
                      feed: [],
                      info: [[], []],
                    },
                  ];
                }
              "
            >
              <div class="wrapper">{{ i.text }}</div>
            </button>

            <button
              @click="
                () => {
                  step = 'body-1';
                  index++;
                }
              "
              :disabled="!surveyBody"
              class="btn button gold mt-3"
            >
              <div class="wrapper">
                下一題 <img src="./images/footprint.png" />
              </div>
            </button>
          </div>
        </b-card>
      </div>
    </div>

    <!--survey-body-1-->
    <div class="survey p-3" v-else-if="step == 'body-1' && surveyBody">
      <div class="survey-wrapper p-3">
        <img
          v-if="surveyBodyValue == 'dog'"
          src="./images/survey-dog.png"
          class="banner"
        />
        <img
          v-else-if="surveyBodyValue == 'cat'"
          src="./images/survey-cat.png"
          class="banner"
        />
        <img v-else src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <div class="number mb-3">Q{{ index }}</div>
            <h5 class="mb-0">{{ surveyBody[bodyIndex * 3 + 0].question }}</h5>

            <div
              v-for="(p, pdx) in result.body[bodyIndex].profiles"
              :key="pdx"
              class="mt-5"
            >
              <hr v-if="pdx > 0" />
              <h5 class="mb-0">
                {{ surveyBody[bodyIndex * 3 + 0].variety.question }}
              </h5>
              <b-select
                class="mt-3"
                v-model="p.variety"
                @change="
                  (option) => {
                    if (option.size != undefined) {
                      p.size = option.size[0];
                    }
                  }
                "
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- 請選擇 --</b-form-select-option
                  >
                </template>
                <b-select-option
                  v-for="(i, idx) in surveyBody[bodyIndex * 3 + 0].variety
                    .options"
                  :key="idx"
                  :value="i"
                >
                  {{ i.text }}
                </b-select-option>
              </b-select>

              <template
                v-if="
                  p.variety &&
                  p.variety.size != undefined &&
                  p.variety.size.length > 1
                "
              >
                <h5 class="mb-0 mt-3">
                  {{ surveyBody[bodyIndex * 3 + 0].size.question }}
                </h5>
                <b-select
                  class="mt-3"
                  :options="p.variety.size"
                  v-model="p.size"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- 請選擇 --</b-form-select-option
                    >
                  </template>
                </b-select>
              </template>

              <h5 class="mb-0 mt-3">
                {{ surveyBody[bodyIndex * 3 + 0].age.question }}
              </h5>
              <small class="text-secondary">{{
                surveyBody[bodyIndex * 3 + 0].age.helper
              }}</small>
              <div class="d-flex mt-3 align-items-center">
                <b-select
                  :options="surveyBody[bodyIndex * 3 + 0].age.year"
                  v-model="p.year"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- 請選擇 --</b-form-select-option
                    >
                  </template>
                </b-select>
                <span class="mx-3">歲</span>
                <b-select
                  :options="surveyBody[bodyIndex * 3 + 0].age.month"
                  v-model="p.month"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- 請選擇 --</b-form-select-option
                    >
                  </template></b-select
                >
                <span class="mx-3">月</span>
              </div>

              <button
                v-if="pdx > 0"
                class="btn button mt-3"
                @click="
                  () => {
                    result.body[bodyIndex].profiles.pop();
                  }
                "
              >
                <div class="wrapper">移除</div>
              </button>
            </div>

            <button
              v-if="result.body[bodyIndex].profiles.length < 10"
              @click="
                () => {
                  result.body[bodyIndex].profiles.push({
                    variety: null,
                    size: null,
                    year: null,
                    month: null,
                  });
                }
              "
              class="btn button mt-3"
            >
              <div class="wrapper">
                還有其他{{ surveyBody[bodyIndex * 3 + 0].name }}
              </div>
            </button>

            <button
              @click="
                () => {
                  result.body[bodyIndex].profiles.forEach((p) => {
                    p.key = surveyBody[bodyIndex * 3 + 0].key;
                  });

                  step = surveyBody[bodyIndex * 3 + 0].next;
                  index++;
                }
              "
              class="btn button gold mt-3"
              :disabled="profileDisabled(result.body[bodyIndex].profiles)"
            >
              <div class="wrapper">
                {{ surveyBody[bodyIndex * 3 + 0].button }}
                <img src="./images/footprint.png" />
              </div>
            </button>

            <button
              @click="
                () => {
                  step = surveyBody[bodyIndex * 3 + 0].prev;
                  if (surveyBodyValue == 'both' && bodyIndex == 1) {
                    bodyIndex--;
                  }
                  index--;
                }
              "
              class="btn btn-link text-dark h5 font-weight-bolder mt-3"
            >
              <i class="fa fa-caret-left" aria-hidden="true"></i>
              回上一頁
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <!--survey-body-2-->
    <div class="survey p-3" v-else-if="step == 'body-2'">
      <div class="survey-wrapper p-3">
        <img
          v-if="surveyBodyValue == 'dog'"
          src="./images/survey-dog.png"
          class="banner"
        />
        <img
          v-else-if="surveyBodyValue == 'cat'"
          src="./images/survey-cat.png"
          class="banner"
        />
        <img v-else src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <div class="number mb-3">Q{{ index }}</div>

            <h5 class="mb-0">{{ surveyBody[bodyIndex * 3 + 1].question }}</h5>
            <h6 class="mb-0">{{ surveyBody[bodyIndex * 3 + 1].sub }}</h6>

            <button
              :class="[
                'btn',
                'button',
                'mt-3',
                result.body[bodyIndex].feed.includes(i.text) && 'active',
              ]"
              v-for="(i, idx) in surveyBody[bodyIndex * 3 + 1].options"
              :key="idx"
              @click="
                () => {
                  let els = result.body[bodyIndex].feed;
                  els.includes(i.text)
                    ? els.splice(els.indexOf(i.text), 1)
                    : els.push(i.text);
                }
              "
            >
              <div class="wrapper">
                {{ i.text }}
                <div class="small h6 mb-0 mt-1">{{ i.helper }}</div>
              </div>
            </button>

            <button
              @click="
                () => {
                  step = surveyBody[bodyIndex * 3 + 1].next;
                  index++;
                }
              "
              class="btn button gold mt-3"
              :disabled="result.body[bodyIndex].feed.length == 0"
            >
              <div class="wrapper">
                {{ surveyBody[bodyIndex * 3 + 1].button }}
                <img src="./images/footprint.png" />
              </div>
            </button>

            <button
              @click="
                () => {
                  step = surveyBody[bodyIndex * 3 + 1].prev;
                  index--;
                }
              "
              class="btn btn-link text-dark h5 font-weight-bolder mt-3"
            >
              <i class="fa fa-caret-left" aria-hidden="true"></i>
              回上一頁
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <!--survey-body-3-->
    <div class="survey body-3 p-3" v-else-if="step == 'body-3'">
      <div class="survey-wrapper p-3">
        <img
          v-if="surveyBodyValue == 'dog'"
          src="./images/survey-dog.png"
          class="banner"
        />
        <img
          v-else-if="surveyBodyValue == 'cat'"
          src="./images/survey-cat.png"
          class="banner"
        />
        <img v-else src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <div class="number mb-3">Q{{ index }}</div>

            <h5>{{ surveyBody[bodyIndex * 3 + 2].question }}</h5>
            <h6 class="mb-0" v-html="surveyBody[bodyIndex * 3 + 2].sub"></h6>
            <small class="text-secondary">{{
              surveyBody[bodyIndex * 3 + 2].helper
            }}</small>

            <div
              class="card mb-4"
              v-for="(i, idx) in surveyBody[bodyIndex * 3 + 2].questions"
              :key="idx"
            >
              <div class="card-header p-2 bg-danger text-white">
                {{ i.question }}
                <div>▼</div>
              </div>
              <div class="card-body py-2">
                <b-form-checkbox-group
                  :options="i.options"
                  v-model="result.body[bodyIndex].info[idx]"
                >
                </b-form-checkbox-group>
              </div>
            </div>

            <button
              @click="
                () => {
                  if (
                    surveyBodyValue == 'both' &&
                    surveyBody[bodyIndex * 3 + 2].next != 'buy_product'
                  ) {
                    step = surveyBody[bodyIndex * 3 + 2].next;

                    bodyIndex++;

                    result.body.push({
                      key: surveyBody[bodyIndex * 3].key,
                      profiles: [
                        { variety: null, size: null, year: null, month: null },
                      ],
                      feed: [],
                      info: [[], []],
                    });

                    index++;
                  } else {
                    step = surveyBody[bodyIndex * 3 + 2].next;
                    index++;
                  }
                }
              "
              class="btn button gold mt-3"
              :disabled="
                !(
                  result.body[bodyIndex].info.length == 2 &&
                  result.body[bodyIndex].info.every((x) => {
                    return x.length > 0;
                  })
                )
              "
            >
              <div class="wrapper">
                {{ surveyBody[bodyIndex * 3 + 2].button }}
                <img src="./images/footprint.png" />
              </div>
            </button>

            <button
              @click="
                () => {
                  step = surveyBody[bodyIndex * 3 + 2].prev;
                  index--;
                }
              "
              class="btn btn-link text-dark h5 font-weight-bolder mt-3"
            >
              <i class="fa fa-caret-left" aria-hidden="true"></i>
              回上一頁
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <!--survey-buy-product-->
    <div class="survey buy_product p-3" v-else-if="step == 'buy_product'">
      <div class="survey-wrapper p-3">
        <img src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <div class="number mb-3">Q{{ index }}</div>

            <h5 class="mb-0" v-html="survey.buy_product.question"></h5>
            <small class="text-secondary">{{ survey.buy_product.sub }}</small>

            <button
              :class="[
                'btn',
                'button',
                'mt-3',
                result.buy_product == i.value && 'active',
              ]"
              v-for="(i, idx) in survey.buy_product.options"
              :key="idx"
              @click="
                () => {
                  result.buy_product = i.value;
                  $forceUpdate();
                }
              "
            >
              <div class="wrapper">
                {{ i.text }}
              </div>
            </button>

            <button
              @click="send"
              class="btn button gold mt-3"
              :disabled="!result.buy_product"
            >
              <div class="wrapper">
                {{ survey.buy_product.button }}
                <img src="./images/footprint.png" />
              </div>
            </button>

            <!-- <button
              @click="
                () => {
                  step = surveyBody[bodyIndex * 3 + 1].prev;
                  index--;
                }
              "
              class="btn btn-link text-dark h5 font-weight-bolder mt-3"
            >
              <i class="fa fa-caret-left" aria-hidden="true"></i>
              回上一頁
            </button> -->
          </div>
        </b-card>
      </div>
    </div>
    <!--survey-finished-->
    <div class="finished" v-else-if="step == 'finished'">
      <div class="survey p-3">
        <div class="survey-wrapper p-3">
          <div class="text-center d-flex flex-column finished-wrapper">
            <h3 class="text-danger font-weight-bold mt-4">感謝您的填寫</h3>
            <h5 class="mt-4">
              您會收到 <br />
              專屬毛孩訊息及活動
            </h5>
            <div class="mt-4">如有任何資訊更新</div>
            <div>請至帳號選單點選更多服務</div>
            <div class="mb-5">
              填寫「<span class="text-danger font-weight-bolder">寵物資料更新</span
              >」
            </div>

            <div class="bottom mt-auto">
              <img src="./images/finished-bottom.png" />
              <button class="btn button gold mt-3" @click="cancel">
                <div class="wrapper">
                  回到LINE
                  <img src="./images/footprint.png" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="bottom">
        <img src="./images/finished-bottom.png" />
        <div class="button-wrapper">
          <button class="btn button gold mt-3" @click="cancel">
            <div class="wrapper">
              回到LINE
              <img src="./images/footprint.png" />
            </div>
          </button>
        </div>
      </div> -->
    </div>

    <!--survey-reset-->
    <div class="survey reset p-3" v-else-if="step == 'reset'">
      <div class="survey-wrapper p-3">
        <img src="./images/survey-both.png" class="banner" />
        <b-card class="text-center paper" no-body>
          <div class="px-4 py-5">
            <h5 class="mt-4 text-danger font-weight-bold">溫馨提醒</h5>

            <div class="mt-5">您已填寫過問卷</div>
            <div class="">繼續填寫將會</div>
            <div class="h5 text-danger">更新並覆蓋過去所有資料</div>

            <button
              class="btn button mt-5"
              @click="
                () => {
                  step = 'index';
                }
              "
            >
              <div class="wrapper">是，繼續填寫</div>
            </button>
            <button class="btn button mt-3" @click="cancel">
              <div class="wrapper">否，關閉問卷</div>
            </button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import survey from "./survey.json";
import liff from "@line/liff";
import axios from "axios";
import {
  setTitle,
  setFavicon,
  setMeta,
} from "../../../store/modules/liff/helpers";

export default {
  data() {
    return {
      liffId: null,
      eventCode: "royalcanin_2022",
      step: "",
      survey: null,
      index: 1,
      surveyBody: null,
      surveyBodyValue: null,
      bodyIndex: 0,
      liffInfo: null,
      liffMe: null,
      liffEvent: null,
      initData: null,
      token: null,

      result: {
        router: null,
        body: [
          // {
          //   key: null,
          //   profiles: [
          //     { key: null, variety: null, size: null, year: null, month: null },
          //   ],
          //   feed: [],
          //   info: [],
          // },
        ],
      },
    };
  },
  computed: {},
  async created() {
    this.survey = survey;
  },
  async mounted() {
    let _this = this;

    await axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "/" +
          this.$route.params.orgCode +
          "/liff/init"
      )
      .then((response) => {
        if (response.data.data.liff_id) {
          _this.liffId = response.data.data.liff_id;
          _this.initData = response.data.data;
        }
      })
      .catch(() => {
        _this.liffFailed();
      });

    const liffId = process.env.VUE_APP_LIFF_ID || _this.liffId;

    await liff.init({ liffId });

    if (!liff.isLoggedIn()) {
      liff.login({
        redirectUri: window.location.href,
      });
    } else {
      _this.token = liff.getAccessToken();

      await _this.getLiffInfo();
      await _this.getLiffMe();
      await _this.getLiffEvent();
    }
  },
  methods: {
    format,
    liffFailed() {
      alert("初始化頁面失敗，請確認此網址是否正確");
    },
    convertPetType(pet_type) {
      if (pet_type == '貓') return '有養貓';
      if (pet_type == '狗') return '有養狗';
      if (pet_type == '都有') return '養貓跟狗';

      return pet_type;
    },
    profileDisabled(profiles) {
      let r = false;
      profiles.forEach((e) => {
        if (!e.variety || !e.month || !e.year) {
          r = true;
        } else if (e.variety.size !== undefined && e.variety.size && !e.size) {
          r = true;
        }
      });
      return r;
    },
    cancel() {
      window.location.href = this.initData.line_oa_url;
    },
    async getLiffInfo() {
      let _this = this;
      const headers = {
        Authorization: `Bearer ${_this.token}`,
      };

      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/info`,
          { headers }
        )
        .then((response) => {
          console.log(response);
          _this.liffInfo = response.data.data;

          setTitle(_this.liffInfo.liff_og_title);
          setFavicon(_this.liffInfo.liff_icon_url);
          setMeta("og:site_title", _this.liffInfo.liff_og_title);
          setMeta("og:description", _this.liffInfo.liff_og_description);
          setMeta("og:image", _this.liffInfo.liff_og_image);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLiffMe() {
      let _this = this;
      const headers = {
        Authorization: `Bearer ${_this.token}`,
      };

      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/me`,
          { headers }
        )
        .then((response) => {
          console.log(response);
          _this.liffMe = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLiffEvent() {
      let _this = this;
      const headers = {
        Authorization: `Bearer ${_this.token}`,
      };

      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/events/${_this.eventCode}`,
          { headers }
        )
        .then((response) => {
          console.log(response);
          _this.liffEvent = response.data.data;

          _this.step = _this.liffEvent.last_record ? "reset" : "index";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async send() {
      var _this = this;
      const headers = {
        Authorization: `Bearer ${_this.token}`,
      };

      //register

      let register = {};

      if (this.liffMe.name) register["name"] = this.liffMe.name;
      if (this.liffMe.email) register["email"] = this.liffMe.email;
      if (this.liffMe.mobile) register["mobile"] = this.liffMe.mobile;
      if (this.liffMe.birthday) register["birthday"] = this.liffMe.birthday;
      if (this.liffMe.sex) register["sex"] = this.liffMe.sex;
      if (this.liffMe.extra) register["extra"] = this.liffMe.extra;

      await axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/${_this.$route.params.orgCode}/liff/general/register`,
          register,
          { headers }
        )
        .then((response) => {
          if (response.status == 200) {
            var r = {
              applied_at: format(new Date(), "yyyy-MM-dd"),
              gift_source:
                _this.result.router != undefined ? _this.result.router : null,
              pet_type: _this.convertPetType(_this.result.pet_type),
              buy_product: _this.result.buy_product,
            };

            for (var t = 0; t < 2; t++) {
              let key = t == 0 ? "cat" : "dog";

              for (var idx = 0; idx < 10; idx++) {
                r[`${key}_${idx + 1}_type`] = null;
                r[`${key}_${idx + 1}_year`] = null;
                r[`${key}_${idx + 1}_mon`] = null;
                if (key == "dog") r[`${key}_${idx + 1}_size`] = null;
              }

              r[`${key}_food`] = null;
              r[`${key}_disease`] = null;
              r[`${key}_rearing`] = null;
            }

            _this.result.body.forEach((t) => {
              t.profiles.forEach((p, idx) => {
                r[`${t.key}_${idx + 1}_type`] = p.variety.text;
                r[`${t.key}_${idx + 1}_year`] = p.year;
                r[`${t.key}_${idx + 1}_mon`] =
                  p.month == "不清楚" ? "6" : p.month;
                if (t.key == "dog") r[`${t.key}_${idx + 1}_size`] = p.size;
              });

              r[`${t.key}_food`] = t.feed.join(";");
              r[`${t.key}_disease`] = t.info[0].join(";");
              r[`${t.key}_rearing`] = t.info[1].join(";");
            });

            //console.log(r);

            axios
              .post(
                `${process.env.VUE_APP_API_BASE_URL}/${_this.$route.params.orgCode}/liff/general/events/${_this.eventCode}`,
                r,
                { headers }
              )
              .then((response) => {
                if (response.status == 200) {
                  _this.step = "finished";
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.container {
  max-width: 750px;
  margin: auto;
  position: relative;
  padding: 0;
}

.index {
  // background: url(images/index-bottom-bg.png);
  // background-repeat: no-repeat;
  // background-position: center 93%;
  // background-size: 100%;
  // background-attachment: fixed;

  .index-wrapper {
    text-align: center;
    background: url(images/index-bg.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    padding: 1.5rem;
    padding-top: 15%;
    padding-bottom: 68%;
    min-height: 96vh;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    &.ball {
      animation: ball 1s ease-in-out infinite;

      @keyframes ball {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-30px);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }
  .bottom-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.survey {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 13px;
    top: 13px;
    right: 13px;
    bottom: 13px;
    border: solid 1px #cebda5;
  }

  .survey-wrapper {
    border: dotted 2px #cebda5;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      border: solid 1px #cebda5;
      z-index: -1;
    }

    .banner {
      margin-top: -2rem;
    }

    .paper {
      background: url(images/footprint-bg.png),
        url(images/survey-pattern-top.png),
        url(images/survey-pattern-bottom.png);
      background-size: 80%, 95%, 95%;
      background-position: center top 2rem, center top 0.5rem,
        center bottom 0.5rem;
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-color: white;
    }

    .number {
      padding: 0.6rem;
      width: 55px;
      height: 55px;
      display: inline-block;
      background: rgb(196, 150, 95);
      background: linear-gradient(
        90deg,
        rgba(196, 150, 95, 1) 0%,
        rgba(255, 206, 140, 1) 50%,
        rgba(196, 150, 95, 1) 100%
      );
      border-radius: 100%;
      color: white;
      font-weight: 900;
      font-size: 16pt;
      text-shadow: 2px 2px 2px rgba(125, 88, 43, 0.5);

      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border: solid 3px white;
        border-radius: 100%;
      }
    }
  }
}

.body-3 {
  .bv-no-focus-ring {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .custom-checkbox {
      width: 50%;
      margin: 0;
      padding: 0.2rem 0.3rem;
      position: relative;
      .custom-control-input {
        position: static;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #cc2d28;
        border-color: #cc2d28;
      }
    }
  }
}

.finished {
  .survey-wrapper {
    height: 95vh;
  }

  .finished-wrapper {
    height: 100%;
  }

  .bottom {
    transform: scale(1.25);
    transform-origin: top center;
    position: relative;

    .btn {
      position: absolute;
      bottom: 10%;
      transform: scale(0.75);

      width: 60%;
      left: 50%;
      margin-left: -30%;
    }
  }
}
</style>
